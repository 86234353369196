import { getChildrenOnDisplayName } from '@core/utils/getChildrenOnDisplayName';
import Head from 'next/head';
import React from 'react';
import { Navbar, NavbarInterface } from 'uangcermat-web-toolkit-v2';

interface ContentLayout extends NavbarInterface {
  pageTitle?: string;
  children: React.ReactNode;
}

const ContentLayout = ({
  isShowClose,
  onClickClose,
  containerStyles,
  pageTitle,
  children
}: ContentLayout) => {
  const title = getChildrenOnDisplayName(children, 'Title');
  const content = getChildrenOnDisplayName(children, 'Content');

  const headTitle = pageTitle
    ? `${pageTitle} -  ${process.env.APP_NAME} Client Portal`
    : `${process.env.APP_NAME} Client Portal`;

  return (
    <>
      <Head>
        <title>{headTitle}</title>
        <meta name="description" content={headTitle} />
        <meta name="keywords" content={headTitle} />
      </Head>
      <div className="flex flex-col w-full">
        <Navbar
          isShowClose={isShowClose}
          onClickClose={() => onClickClose?.()}
          containerStyles={containerStyles}
        >
          {!title ? <Navbar.Title label={pageTitle} /> : <Navbar.Title>{title}</Navbar.Title>}
        </Navbar>

        {content}
      </div>
    </>
  );
};

const Title = ({ children }: { children: React.ReactNode }) => {
  return <>{children}</>;
};
Title.displayName = 'Title';
ContentLayout.Title = Title;

const Content = ({ children }: { children: React.ReactNode }) => {
  return <>{children}</>;
};
Content.displayName = 'Content';
ContentLayout.Content = Content;

export default ContentLayout;
